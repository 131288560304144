import { useState, useEffect } from "react";
import strings from './strings.json';
import axios from 'axios';

function Services({ lang }) {

  const [modal, setModal] = useState(false);
  const [addmodal, setAddModal] = useState(false);

  const [servId, setServId] = useState("");
  const [servName, setServName] = useState("");
  const [servPrice, setServPrice] = useState("");
  const [last, setLast] = useState(""); // Last index ID of Data

  const openModal = (sId, sName, sPrice) => {
    setServId(sId);
    setServName(sName);
    setServPrice(sPrice);
    setModal(!modal);
    const modalDiv = document.querySelector(".booking-modal__car-info");
    modalDiv.scroll(0, 0);
  }

  const closeModal = () => {
    setModal(!modal);
  }

  const addModal = () => {
    setAddModal(!addmodal);
    const modalDiv = document.querySelector(".booking-modal__car-info");
    modalDiv.scroll(0, 0);
  }

  const [data, setData] = useState([]);

  const fetchData = async(e) => {
    try {
      const response = await axios({
        method: "get",
        url: "https://api.hardrockrental.net/getServices.php",
        headers: { "Content-Type": "multipart/form-data" },
      });
      setData(response.data);
      setLast(response.data[response.data.length-1].id+1);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  }

  useEffect(() => {
      fetchData();
  }, []);

  var bodyFormData = new FormData();
  bodyFormData.append('id', servId);
  bodyFormData.append('service', servName);
  bodyFormData.append('price', servPrice);

  const updateService = async() => {
    try {
      const response = await axios({
        method: "post",
        url: "https://api.hardrockrental.net/updateService.php",
        data: bodyFormData,
        headers: { "Content-Type": "multipart/form-data" },
      });
      console.log(response.data);
      setModal(!modal);
      fetchData();
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  }

  var formData = new FormData();
  formData.append('id', last);

  const addService = async() => {
    formData.append('service', servName);
    formData.append('price', servPrice);
    try {
      const response = await axios({
        method: "post",
        url: "https://api.hardrockrental.net/addService.php",
        data: formData,
        headers: { "Content-Type": "multipart/form-data" },
      });
      console.log(response.data);
      setAddModal(!addmodal);
      fetchData();
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  }
  
  return (
    <>
      <div className="team-container" style={{ paddingBottom: "0px" }}>
        <p>
        {strings[lang].Vehicles1} {sessionStorage.getItem("userdata").toUpperCase()}{" "}
          <i className="fa-regular fa-face-grin-beam"></i>
          <br></br> {strings[lang].Vehicles2} <b>{data.length} {strings[lang].Services1}</b>
        </p>
        <p></p>
        <button type="submit" style={{ borderRadius: "50px", boxShadow: "none"}} onClick={() => addModal()}>{strings[lang].Services2}</button><br></br>
      </div>
      <div className="book-content__box">
      <table
                style={{
                  alignSelf: "center",
                  textAlign: lang === "ar" ? "right" : "left",
                  fontSize: "15px"
                }}
              >
                <tr>
                  <th>#</th>
                  <th>{strings[lang].Services4}</th>
                  <th>{strings[lang].Services5}</th>
                </tr>
        {data &&
          data.map(function (d, idx) {
            return (
                <tr key={idx}>
                  <td>{d.id}</td>
                  <td>{d.service}</td>
                  <td>{d.price}</td>
                  <td style={{ padding: "2rem"}}><i className="fa-solid fa-pen-to-square" style={{color: "red"}} onClick={() => openModal(d.id, d.service, d.price)}></i></td>
                </tr>
            );
          })}</table>
        {/* <div className="team-container__box">
                <h2><i className="fa-solid fa-user-tie fa-sm"></i>&nbsp; {sessionStorage.getItem('userdata').toUpperCase()}</h2>
                <br></br>
                <button type="submit">
                  Services
                </button>
                <br></br>
                <button type="submit">
                  Rent registry
                </button>
                <br></br>
                <button type="submit">
                  Users
                </button> 
              </div> */}
      </div>

      {/* modal ------------------------------------ */}

      <div className={`booking-modal ${modal ? "active-modal" : ""}`}>
        {/* title */}
        {/* car info */}
        <div className="booking-modal__car-info">
              <div className="team-container__box">
                <h2><i className="fa-solid fa-pen-to-square fa-2xl" onClick={() => closeModal()}></i></h2><br></br>
                <h3 style={{ textAlign: "left", marginTop: "1.5rem"}}>#{servId === "" ? "" : servId}</h3>
                <input type="text" placeholder={servName} value={servName} onChange={(e) => setServName(e.target.value)} />
                <input type="number" placeholder={servPrice} value={servPrice} onChange={(e) => setServPrice(e.target.value)} />
                <button type="submit" onClick={() => updateService()}>
                {strings[lang].Vehicles38}
                </button> 
              </div>
        </div>
      </div>

      {/* modal ------------------------------------ */}

      <div className={`booking-modal ${addmodal ? "active-modal" : ""}`}>
        {/* title */}
        {/* car info */}
        <div className="booking-modal__car-info">
              <div className="team-container__box">
                <h2><i className="fa-solid fa-pen-to-square fa-2xl" onClick={() => setAddModal(!addmodal)}></i></h2><br></br>
                <h3 style={{ textAlign: "left", marginTop: "1.5rem"}}>#{last}</h3>
                <input type="text" placeholder={strings[lang].Services4} onChange={(e) => setServName(e.target.value)} />
                <input type="number" placeholder={strings[lang].Services6} onChange={(e) => setServPrice(e.target.value)} />
                <button type="submit" onClick={() => addService()}>
                {strings[lang].Vehicles37}
                </button> 
              </div>
        </div>
      </div>
    </>
  );
}

export default Services;
