import { useState, useEffect, useCallback } from "react";
import Footer from "../components/Footer";
import HeroPages from "../components/HeroPages";
import CarBox from "../components/CarBox";
import { useLocation } from "react-router-dom";
import Navbar from "../components/Navbar";
import strings from '../components/strings.json';
import axios from 'axios';

function Models() {
  const [colorBtn, setColorBtn] = useState("btn1");
  const location = useLocation();
  const [active, setActive] = useState(0);
  const [count, setCount] = useState();
  
  const firstCar = useCallback(async () => {
    var formData = new FormData();
  formData.append('Type', location.state.carType);
  formData.append('Name', location.state.carsData[0].name);
  formData.append('Model', location.state.carsData[0].model);
  formData.append('car_name', location.state.carsData[0].name+" "+location.state.carsData[0].model);
  formData.append('car_out', location.state.pickTime);
  formData.append('car_in', location.state.dropTime);
    try {
      const response = await axios({
        method: "post",
        url: "https://api.hardrockrental.net/getCarsReserved.php",
        data: formData,
        headers: { "Content-Type": "multipart/form-data" },
      });
      setCount(response.data[0]["available"]);
      // console.log("available", count);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  }, [location.state.carType, location.state.carsData, location.state.pickTime, location.state.dropTime]);
  
  useEffect(() => {
    firstCar();
  }, [firstCar]);

  const btnID = (id) => {
    setColorBtn(colorBtn === id ? "" : id);
  };

  const coloringButton = (id) => {
    return colorBtn === id ? "colored-button" : "";
  };

  var bodyFormData = new FormData();
  bodyFormData.append('Type', location.state.carType);

  const handleChange = async ({idx}, name, model) => {
    bodyFormData.append('Name', name);
    bodyFormData.append('Model', model);
    bodyFormData.append('car_name', name+" "+model);
    bodyFormData.append('car_out', location.state.pickTime);
    bodyFormData.append('car_in', location.state.dropTime);
      setActive(idx);
      btnID("btn1");
      try {
        const response = await axios({
          method: "post",
          url: "https://api.hardrockrental.net/getCarsReserved.php",
          data: bodyFormData,
          headers: { "Content-Type": "multipart/form-data" },
        });
        setCount(response.data[0]["available"]);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
  };

  return (
    <>
    <Navbar />
      <section className="models-section">
      <HeroPages name="Vehicles" />
        <div className="container">
          <div className="pick-container">
            <div className="pick-container__title">
            </div>
            <h3>{location.state.carType}</h3><br></br>
            <div className="pick-container__car-content">
              {/* pick car */}
              <div className="pick-box">
                {location.state.carsData && (location.state.carsData).map(function(d, idx)
                { return (
                  <button
                    key={idx}
                    className={`${coloringButton("btn2")}`}
                    id={"btn1"}
                    value={idx}
                    onClick={() => handleChange({ idx }, d.name, d.model)}>
                    {d.name} {d.model}
                  </button>
                );
              })}
              </div>
              <CarBox data={location.state.carsData} booking={location.state} carID={active} counter={count} strings={strings} lang={location.state.lang} />
            </div>
          </div>
        </div>
        <div className="book-banner">
          <div className="book-banner__overlay"></div>
          <div className="container">
            <div className="text-content">
              <h2>{strings[location.state.lang].Models1}</h2>
              <span>
                <i className="fa-solid fa-phone"></i>
                <h3>+973 17005001</h3>
              </span>
            </div>
          </div>
        </div>
        <Footer lang={location.state.lang} />
      </section>
    </>
  );
}

export default Models;
