import "../src/dist/styles.css";
import Receipt from "./Pages/Receipt";
import Home from "./Pages/Home";
import { Route, Routes } from "react-router-dom";
import Models from "./Pages/Models";
import Upload from "./Pages/Upload";
import Reservation from "./Pages/Reservation";
import Panel from "./Pages/Panel";

function App() {
  return (
    <>
      <Routes>
        <Route index path="/" element={<Home />} />
        <Route path="receipt" element={<Receipt />} />
        <Route path="models" element={<Models />} />
        <Route path="upload" element={<Upload />} />
        <Route path="reservation" element={<Reservation />} />
        <Route path="panel" element={<Panel />} />
      </Routes>
    </>
  );
}

export default App;
