import strings from './strings.json';
import { useState } from "react";
import cyphr from "../images/banners/cyphr.png";
import formimg from "./formimg.json";

function Footer({ lang }) {
  const [addmodal, setAddModal] = useState(false);
  const [ownersmodal, setOwnersModal] = useState(false);

  const openModal = () => {
    setAddModal(true);
    document.body.style.overflow = "hidden";
  }

  const closeModal = () => {
    setAddModal(!addmodal)
    document.body.style.overflow = "auto";
  }

  const openownersModal = () => {
    setOwnersModal(true);
    document.body.style.overflow = "hidden";
  }

  const closeownersModal = () => {
    setOwnersModal(!ownersmodal)
    document.body.style.overflow = "auto";
  }

  return (
    <>
      <footer>
        <div className="container">
          <div className="footer-content">
            <ul className="footer-content__1">
              <li>
                <span>{strings[lang].Footer9}</span>
              </li>
              <li>
              <p>{strings[lang].Footer17}</p><br></br>
              <a href="tel:+97317005001">
                  <i className="fa-solid fa-phone"></i>&nbsp; +973 17005001
                </a>
                <a href="tel:+97336420333"><br></br>
                  <i className="fa-brands fa-whatsapp"></i>&nbsp; +973 36420333
                </a>
                <a href="tel:+97336333642"><br></br>
                <i className="fa-brands fa-whatsapp"></i>&nbsp; +973 36333642
                </a><br></br>
                <a
                  href="https://www.instagram.com/hrr.rental"
                >
                 <i className="fa-brands fa-instagram"></i>
                  &nbsp; hrr.rental
                </a>
                <a href="mailto:team@hardrockrental.net"><br></br>
                  <i className="fa-solid fa-envelope"></i>&nbsp;team@hardrockrental.net
                </a>
              </li>
            </ul>

            <ul className="footer-content__2">
              <li>{strings[lang].Footer1}</li>
              <li>
                <a href="https://goo.gl/maps/dnrrwiTMLVSpGMiA6">{strings[lang].Footer7}
                <br></br> {strings[lang].Footer23}
                <br></br> {strings[lang].Footer24}</a>
                <br></br>
                <br></br>
                <ul onClick={openownersModal} >{">"} {strings[lang].Footer18}</ul><br></br>
                <a href="https://hardrockrental.net/policies.html">{">"} {strings[lang].Footer10}</a><br></br>
              </li>
              <li>{">"} Developed by <img src={cyphr} style={{width: "55px", marginTop: "1rem" }} onClick={()=> window.open("https://www.instagram.com/cyphr.bh/", "_blank")} alt="logo-img" /></li>
            </ul>

            <ul className="footer-content__2">
              <li>{strings[lang].Footer2}</li>
              <li>{strings[lang].Footer3}</li>
            </ul>

            <ul className="footer-content__2">
              <li>{strings[lang].Footer4}</li>
              <li>
                <p>{strings[lang].Footer5}</p>
              </li>
              <li>
                <button className="submit-email" onClick={openModal}>{strings[lang].Footer6}</button>
              </li>
            </ul>
          </div>
        </div>
      </footer>
      {/* modal ------------------------------------ */}
      <div className={`booking-modal ${addmodal ? "active-modal" : ""}`}>
        {/* title */}
        {/* car info */}
        <div className="booking-modal__car-info">
              <div className="team-container__box">
                <h2><i className="fa-solid fa-circle-exclamation fa-2xl"></i></h2><br></br>
                <h1 style={{ marginTop: "1rem"}}>{strings[lang].Footer12}</h1>
                <h2 style={{ textAlign: "left", marginTop: "1.5rem"}}>{strings[lang].Footer13}</h2>
                <h2 style={{ textAlign: "left", marginTop: "0.5rem"}}>{strings[lang].Footer14}</h2>
                <h2 style={{ textAlign: "left", marginTop: "0.5rem"}}>{strings[lang].Footer15}</h2>
                <h2 style={{ textAlign: "left", marginTop: "0.5rem"}}>{strings[lang].Footer16}</h2>
                <a href="https://hardrockrental.net/terms.pdf" target="_blank" style={{ textAlign: "left", marginTop: "1.5rem", marginBottom: "1.5rem", fontSize: "1.6rem", color: "black", fontFamily: "sans-serif", textDecoration: "none", fontWeight: "bold" }}>{strings[lang].Footer25}</a>
                <button type="submit" onClick={closeModal}>
                {strings[lang].Footer11}
                </button> 
              </div>
        </div>
      </div>

      {/* owners modal ------------------------------------ */}
      <div className={`booking-modal ${ownersmodal ? "active-modal" : ""}`}>
        {/* title */}
        {/* car info */}
        <div className="booking-modal__car-info">
              <div className="team-container__box">
                <h2><i className="fa-solid fa-users fa-2xl" onClick={closeownersModal}></i></h2><br></br>
                <h1 style={{ marginTop: "1rem"}}>{strings[lang].Footer18}</h1>
                <h2 style={{ textAlign: "left", marginTop: "1.5rem"}}>{strings[lang].Footer19}</h2>
                <h2 style={{ textAlign: "left", marginTop: "0.5rem"}}>{strings[lang].Footer20}</h2>
                <h2 style={{ textAlign: "left", marginTop: "0.5rem", marginBottom: "1.5rem"}}>{strings[lang].Footer21}</h2>
                <button type="submit" onClick={closeownersModal}>
                {strings[lang].Footer22}
                </button> 
              </div>
        </div>
      </div>
    </>
  );
}

export default Footer;
