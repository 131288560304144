import { useState} from "react";
import { useNavigate } from "react-router-dom";
import axios from 'axios';

function CarBox({ data, carID, booking, counter, lang, strings }) {
  const [carLoad, setCarLoad] = useState(true);
  const navigate = useNavigate();

  const toReserve = async (carType, pickUp, dropOff, pickTime, dropTime, Id, name, model, gear, daily, weekly, monthly, lang) => {
    try {
      const response = await axios({
        method: "get",
        url: "https://api.hardrockrental.net/getServices.php",
      });
      navigate("/reservation", { state: {carType: carType, pickUp: pickUp, dropOff: dropOff, pickTime: pickTime, dropTime: dropTime, Id: Id, name: name, model: model, gear: gear, daily: daily, weekly: weekly, monthly: monthly, lang: lang, services: response.data}});
    } catch (error) {
      console.error("Error fetching data:", error);
    }
    }
  
  return (
    <>
      {[data].map((car, id) => (
        <div key={carID} className="box-cars">
          {/* car */}
          <div className="pick-car">
            {carLoad && <span className="loader"></span>}
            <img
              style={{ display: carLoad ? "none" : "block" }}
              src={"https://api.hardrockrental.net/imgs/"+(car[carID].name+car[carID].model).replace(" ","").toLowerCase()}
              onError={e => { e.currentTarget.src = "https://api.hardrockrental.net/imgs/default.jpg" }}
              alt="car_img"
              onLoad={() => setCarLoad(false)}
            />
          </div>
          {/* description */}
          <div className="pick-description">
            <div className="pick-description__price">
              <span>{car[carID].daily} {strings[lang].CarBox1}</span>{strings[lang].CarBox2}
            </div>
            <div className="pick-description__table">
              <div className="pick-description__table__col">
                <span>{strings[lang].CarBox3}</span>
                <span>{car[carID].model}</span>
              </div>

              <div className="pick-description__table__col">
                <span>{strings[lang].CarBox4}</span>
                <span>{car[carID].year}</span>
              </div>

              <div className="pick-description__table__col">
                <span>{strings[lang].CarBox5}</span>
                <span>{car[carID].screen === "YES" ? strings[lang].CarBox14 : strings[lang].CarBox15}</span>
              </div>

              <div className="pick-description__table__col">
                <span>{strings[lang].CarBox6}</span>
                <span>{car[carID].camera === "YES" ? strings[lang].CarBox14 : strings[lang].CarBox15}</span>
              </div>

              <div className="pick-description__table__col">
                <span>{strings[lang].CarBox7}</span>
                <span>{car[carID].sensor === "YES" ? strings[lang].CarBox14 : strings[lang].CarBox15}</span>
              </div>

              <div className="pick-description__table__col">
                <span>{strings[lang].CarBox8}</span>
                <span>{car[carID].bluetooth === "YES" ? strings[lang].CarBox14 : strings[lang].CarBox15}</span>
              </div>

              <div className="pick-description__table__col">
                <span>{strings[lang].CarBox9}</span>
                <span>{car[carID].roof === "YES" ? strings[lang].CarBox14 : strings[lang].CarBox15}</span>
              </div>

              <div className="pick-description__table__col">
                <span>{strings[lang].CarBox10}</span>
                <span>{car[carID].doors}</span>
              </div>

              <div style={{ borderBottomColor: counter === 0 ? "red" : null }} className="pick-description__table__col">
                <span>{strings[lang].CarBox11}</span>
                <span>{car[carID].gear}</span>
              </div>

              <div style={{ borderColor: counter === 0 ? "red" : null, borderRightColor: counter === 0 ? "red" : null }} className="pick-description__table__col">
                <span style={{ color: counter === 0 ? "red" : null, borderRightColor: counter === 0 ? "red" : null }}>{strings[lang].CarBox12}</span>
                <span style={{ color: counter === 0 ? "red" : null, borderRightColor: counter === 0 ? "red" : null }}>{counter}</span>
              </div>
            </div>
            {/* btn cta */}
            <button className="cta-btn" disabled={counter === 0 ? true : false} onClick={() => toReserve(booking.carType, booking.pickUp, booking.dropOff, booking.pickTime, booking.dropTime, car[carID].id, car[carID].name, car[carID].model, car[carID].gear, car[carID].daily, car[carID].weekly, car[carID].monthly, lang)}>
            {strings[lang].CarBox13}
            </button>
          </div>
        </div>
      ))}
    </>
  );
}

export default CarBox;
