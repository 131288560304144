import strings from './strings.json';
function Banner({ lang }) {
  return (
    <>
      <section className="banner-section">
        <div className="container">
          <div className="banner-content">
            <div className="banner-content__text">
              <h2>{strings[lang].Banner1}</h2>
              <p>
              {strings[lang].Banner2} <span>24/7</span> {strings[lang].Banner3}
              </p>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Banner;
