import React, { useState, useEffect } from 'react';
import strings from './strings.json';
import axios from 'axios';

const Upload = ({ lang }) => {

  const [data, setData] = useState([]);
  const [imgName, setImgName] = useState("");
  const [random, setRandom] = useState(Math.random());

  const fetchData = async() => {
    try {
      const response = await axios({
        method: "get",
        url: "https://api.hardrockrental.net/getCarNames.php",
        headers: { "Content-Type": "multipart/form-data" },
      });
      setData(response.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  }

  useEffect(() => {
      fetchData();
  }, [])

  const [selectedFile, setSelectedFile] = useState(null);

  const handleFileChange = (event) => {
    setSelectedFile(event.target.files[0]);
  };

  const handleUpload = async() => {
    if (selectedFile) {
      const formData = new FormData();
      formData.append('image', selectedFile);
      formData.append('fileName', imgName.replace(" ","").toLowerCase());

      try {
        const response = await axios({
          method: "post",
          url: "https://api.hardrockrental.net/upload.php",
          data: formData,
          headers: { "Content-Type": "multipart/form-data" },
        });
        console.log(response.data);
        setSelectedFile(null);
        setRandom(Math.random());
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    }
  };

  return (
    <div className="team-container" style={{ paddingBottom: "0px" }}>
      <div></div>
      <div className="team-container__box">
                <h1><i className="fa-solid fa-arrow-up-from-bracket"></i>&nbsp; {strings[lang].Upload1}</h1>
                <br></br>
                <img
                src={"https://api.hardrockrental.net/imgs/"+imgName.replace(" ","").toLowerCase()+'?n='+random}
                style={{ height: "23rem" }} alt="logo-img"
                onError={e => { e.currentTarget.src = "https://api.hardrockrental.net/imgs/default.jpg" }} />
                <select value={imgName} onChange={e => setImgName(e.target.value)}>
                <option>{strings[lang].Upload2}</option>
                {data && data.map(function(d, idx)
                { return (
                    <option key={idx} value={d.name+""+d.model}>{d.name} {d.model}</option>
                )})}
                </select>
                <input type="file" onChange={handleFileChange} />
                <button onClick={handleUpload}>{strings[lang].Upload3}</button>
              </div>
    </div>
  );
};

export default Upload;
