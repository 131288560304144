import { useState, useEffect } from "react";
import axios from 'axios';
import { Link } from "react-router-dom";
import strings from './strings.json';
import md5 from 'md5';

function Vehicles({ lang }) {

  const [data, setData] = useState([]);
  const [addmodal, setAddModal] = useState(false);
  const random = Math.random();
  const [last, setLast] = useState(""); // Last index ID of Data
  const [carType, setCarType] = useState("");
  const [carName, setCarName] = useState("");
  const [carModel, setCarModel] = useState("");
  const [carPlate, setCarPlate] = useState("");
  const [carYear, setCarYear] = useState("");
  const [carDaily, setCarDaily] = useState("");
  const [carWeekly, setCarWeekly] = useState("");
  const [carMonthly, setCarMonthly] = useState("");
  const [carColor, setCarColor] = useState("");
  const [carDoors, setCarDoors] = useState("");
  const carFuel = "MUMTAZ"
  const [carGear, setCarGear] = useState("AUTO");
  const [carScreen, setCarScreen] = useState("NO");
  const [carBluetooth, setCarBluetooth] = useState("NO");
  const [carRoof, setCarRoof] = useState("NO");
  const [carCamera, setCarCamera] = useState("NO");
  const [carSensor, setCarSensor] = useState("NO");

  const [editmodal, setEditModal] = useState(false); // Edit Modal
  const [editId, setEditId] = useState(""); 
  const [editType, setEditType] = useState("");
  const [editName, setEditName] = useState("");
  const [editModel, setEditModel] = useState("");
  const [editPlate, setEditPlate] = useState("");
  const [editYear, setEditYear] = useState("");
  const [editDaily, setEditDaily] = useState("");
  const [editWeekly, setEditWeekly] = useState("");
  const [editMonthly, setEditMonthly] = useState("");
  const [editColor, setEditColor] = useState("");
  const [editDoors, setEditDoors] = useState("");
  const [editFuel, setEditFuel] = useState("");
  const [editGear, setEditGear] = useState("");
  const [editScreen, setEditScreen] = useState("");
  const [editBluetooth, setEditBluetooth] = useState("");
  const [editRoof, setEditRoof] = useState("");
  const [editCamera, setEditCamera] = useState("");
  const [editSensor, setEditSensor] = useState("");

  const fetchData = async(e) => {
    try {
      const response = await axios({
        method: "get",
        url: "https://api.hardrockrental.net/getAllCars.php",
        headers: { "Content-Type": "multipart/form-data" },
      });
      setFilteredData(response.data);
      setData(response.data);
      setLast(response.data[response.data.length-1].id+1);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  }

  useEffect(() => {
      fetchData();
      if (addmodal && addmodal === true) {
        document.body.style.overflow = "hidden";
      } else {
        document.body.style.overflow = "auto";
      }
  }, [addmodal])

  const addModal = () => {
    setAddModal(!addmodal);
    const modalDiv = document.querySelector(".booking-modal__car-info");
    modalDiv.scroll(0, 0);
  }

  const editModal = (id, name, model, year, type, plate, daily, weekly, monthly, doors, fuel, gear, color, screen, bluetooth, camera, sensor, roof) => {
    setEditId(id);
    setEditName(name);
    setEditModel(model);
    setEditYear(year);
    setEditType(type);
    setEditPlate(plate);
    setEditDaily(daily);
    setEditWeekly(weekly);
    setEditMonthly(monthly);
    setEditDoors(doors);
    setEditFuel(fuel);
    setEditGear(gear);
    setEditColor(color);
    setEditScreen(screen);
    setEditBluetooth(bluetooth);
    setEditCamera(camera);
    setEditSensor(sensor);
    setEditRoof(roof);
    setEditModal(!editmodal);
    const modalDiv = document.querySelector(".booking-modal__car-info");
    modalDiv.scroll(0, 0);
  }

  var formData = new FormData();
  formData.append('id', last);

  const addCar = async() => {
    formData.append('name', carName.toUpperCase());
    formData.append('model', carModel.toUpperCase());
    formData.append('type', carType);
    formData.append('plate', carPlate);
    formData.append('year', carYear);
    formData.append('daily', carDaily);
    formData.append('weekly', carWeekly);
    formData.append('monthly', carMonthly);
    formData.append('doors', carDoors);
    formData.append('color', carColor);
    formData.append('fuel', carFuel);
    formData.append('screen', carScreen);
    formData.append('bluetooth', carBluetooth);
    formData.append('sensor', carSensor);
    formData.append('gear', carGear);
    formData.append('roof', carRoof);
    formData.append('camera', carCamera);

    try {
      const response = await axios({
        method: "post",
        url: "https://api.hardrockrental.net/addCar.php",
        data: formData,
        headers: { "Content-Type": "multipart/form-data" },
      });
      console.log(response.data);
      setAddModal(!addmodal);
      fetchData();
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  }
  
  let [filteredData,setFilteredData] = useState([]);
  const [search, setSearch] = useState("");

  const removeVehicle = async(id) => {
    try {
      const response = await axios({
        method: "post",
        url: "https://api.hardrockrental.net/removeCar.php",
        data: {id: id},
        headers: { "Content-Type": "multipart/form-data" },
      });
      setSearch("");
      fetchData();
      console.log(response.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  }

  const editCar = async() => {
    try {
      await axios({
        method: "post",
        url: "https://api.hardrockrental.net/updateCar.php",
        data: {id: editId, name: editName, model: editModel, year: editYear, type: editType, plate: editPlate, daily: editDaily, weekly: editWeekly, monthly: editMonthly, doors: editDoors, fuel: editFuel, gear: editGear, color: editColor, screen: editScreen, bluetooth: editBluetooth, camera: editCamera, sensor: editSensor, roof: editRoof},
        headers: { "Content-Type": "multipart/form-data" },
      });
      setSearch("");
      fetchData();
      closeEditModal();
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  }

  const closeAddModal = () => {
    setAddModal(!addmodal);
    setCarType("");
    setCarName("");
    setCarModel("");
    setCarPlate("");
    setCarYear("");
    setCarDaily("");
    setCarWeekly("");
    setCarMonthly("");
    setCarColor("");
    setCarDoors("");
    setCarGear("AUTO");
    setCarScreen("NO");
    setCarBluetooth("NO");
    setCarRoof("NO");
    setCarCamera("NO");
    setCarSensor("NO");
  }

  const closeEditModal = () => {
    setEditModal(!editmodal);
  }
  
  const searchSpace = (event) => {
    setSearch(event.target.value);
    console.log(search);
    if(event.target.value === "") {
      setFilteredData(data);
    } else {
      filteredData = data.filter((datas)=>{
        if((datas.name+" "+datas.model).includes(search.toUpperCase()) || (datas.plate).includes(search)){
          return datas
        } else {
          return false;
        }
      });
      setFilteredData(filteredData);
    }
  }
  
  return (
    <>
    <div className="team-container" style={{ paddingBottom: "0px"}}>
    <p>{strings[lang].Vehicles1} {sessionStorage.getItem('userdata').toUpperCase()} <i className="fa-regular fa-face-grin-beam"></i><br></br> {strings[lang].Vehicles2} <b>{data.length} {strings[lang].Vehicles3}</b></p>
    <input placeholder={strings[lang].Vehicles5} value={search} id="search" type="text" onChange={(e)=>searchSpace(e)}/>
    <button type="submit" style={{ borderRadius: "50px", boxShadow: "none"}} onClick={() => addModal()}>{strings[lang].Vehicles4}</button>
    </div>
    <div className="team-container">
              {filteredData && filteredData.map(function(d, idx)
                { return (
                  <div key={idx} className="team-container__box">
                  <h2><i className="fa-solid fa-car-side fa-sm"></i>&nbsp; {d.name+" "+d.model} </h2>
                  <img src={"https://api.hardrockrental.net/imgs/"+(d.name+d.model).replace(" ","").toLowerCase()+'?n='+random} onError={e => { e.currentTarget.src = "https://api.hardrockrental.net/imgs/default.jpg" }} alt="logo-img" />
                  <p>
                  <i className="fa-solid fa-circle" style={{ color: d.color, borderRadius: "50%", border: "solid", borderColor: "black", borderWidth: "2px" }}/>
                  {d.screen === "YES" ? <i className="fa-solid fa-display fa-lg"/> : null}
                  {d.bluetooth === "YES" ? <i className="fa-brands fa-bluetooth fa-lg"/> : null}
                  {d.camera === "YES" ? <i className="fa-solid fa-camera fa-lg"/> : null}
                  {d.sensor === "YES" ? <i className="fa-solid fa-tower-broadcast fa-lg"/> : null}
                  {d.roof === "YES" ? <i className="fa-regular fa-window-maximize fa-lg"/> : null}
                  </p>
                  <p style={{ textAlign: lang === "ar" ? "right" : "left" }}>{strings[lang].Vehicles6} {d.plate}</p>
                  <p style={{ textAlign: lang === "ar" ? "right" : "left" }}>{strings[lang].Vehicles7} {d.daily} &nbsp; {strings[lang].Vehicles8} {d.weekly} &nbsp; {strings[lang].Vehicles9} {d.monthly}</p>
                  <button type="submit" style={{ backgroundColor: "#010103", boxShadow: "none"}} onClick={() => editModal(d.id, d.name, d.model, d.year, d.type, d.plate, d.daily, d.weekly, d.monthly, d.doors, d.fuel, d.gear, d.color, d.screen, d.bluetooth, d.camera, d.sensor, d.roof)}>
                  {strings[lang].Vehicles10}
                  </button>
                  <br></br>
                  {sessionStorage.getItem('usertype') === md5("admin") && <ul className="navbar__links" style={{ alignSelf: "center", marginTop: "1rem", fontSize: "15px" }}>
                    <li>
                      <Link onClick={() => removeVehicle(d.id)}><i className="fa-solid fa-trash fa-sm"></i>{strings[lang].Vehicles39}</Link>
                    </li>
                  </ul>}
                  </div>
                )
              })}
          </div>

          {/* add modal ------------------------------------ */}

      <div className={`booking-modal ${addmodal ? "active-modal" : ""}`} style={{ height: "55%" }}>
        {/* title */}
        {/* car info */}
        <div className="booking-modal__car-info">
              <div className="team-container__box" style={{ overflowY:"scroll" }}>
                <h2><i className="fa-solid fa-car-on fa-2xl" onClick={() => closeAddModal()}></i></h2><br></br>
                <h3 style={{ textAlign: "left", marginTop: "1.5rem"}}>#{last}</h3>
                <span>
                <input type="text" placeholder={strings[lang].Vehicles11} value={carName.toUpperCase()} onChange={(e) => setCarName(e.target.value.replace(/[^\w\s]/gi, ""))} />
                &nbsp; <input type="text" placeholder={strings[lang].Vehicles12} value={carModel.toUpperCase()} onChange={(e) => setCarModel(e.target.value.replace(/[^\w\s]/gi, ""))} />
                </span>
                <input type="number" placeholder={strings[lang].Vehicles13} value={carPlate} onChange={(e) => setCarPlate(e.target.value.replace(/[^\w\s]/gi, ""))} />
                <select value={carType} onChange={(e) => setCarType(e.target.value.replace(/[^\w\s]/gi, ""))}>
                    <option>{strings[lang].Vehicles14}</option>
                    <option value="Hatchback">Hatchback</option>
                    <option value="Sedan">Sedan</option>
                    <option value="SUV">SUV</option>
                    <option value="Bus">Bus</option>
                </select>
                <span>
                  <input type="number" placeholder={strings[lang].Vehicles15} value={carYear} onChange={(e) => setCarYear(e.target.value.replace(/[^\w\s]/gi, ""))} />
                &nbsp; <input type="number" placeholder={strings[lang].Vehicles16} value={carDaily} onChange={(e) => setCarDaily(e.target.value.replace(/[^\w\s]/gi, ""))} />
                </span>
                <span>
                  <input type="number" placeholder={strings[lang].Vehicles17} value={carWeekly} onChange={(e) => setCarWeekly(e.target.value.replace(/[^\w\s]/gi, ""))} />
                &nbsp; <input type="number" placeholder={strings[lang].Vehicles18} value={carMonthly} onChange={(e) => setCarMonthly(e.target.value.replace(/[^\w\s]/gi, ""))} />
                </span>
                <select value={carColor} onChange={(e) => setCarColor(e.target.value.replace(/[^\w\s]/gi, ""))}>
                    <option>{strings[lang].Vehicles19}</option>
                    <option value="WHITE">{strings[lang].Vehicles20}</option>
                    <option value="SILVER">{strings[lang].Vehicles21}</option>
                    <option value="GREY">{strings[lang].Vehicles22}</option>
                    <option value="BLACK">{strings[lang].Vehicles23}</option>
                    <option value="PURPLE">{strings[lang].Vehicles24}</option>
                    <option value="BLUE">{strings[lang].Vehicles25}</option>
                    <option value="GREEN">{strings[lang].Vehicles26}</option>
                    <option value="YELLOW">{strings[lang].Vehicles27}</option>
                    <option value="ORANGE">{strings[lang].Vehicles28}</option>
                    <option value="RED">{strings[lang].Vehicles29}</option>
                </select>
                <span>
                <input type="number" placeholder={strings[lang].Vehicles30} value={carDoors} onChange={(e) => setCarDoors(e.target.value.replace(/[^\w\s]/gi, ""))} />
                &nbsp; <input type="text" placeholder={strings[lang].Vehicles31} value={carGear} onChange={(e) => setCarGear(e.target.value.replace(/[^\w\s]/gi, ""))} />
                </span>
                <div className="box-form__car-type" style={{ marginTop: "1rem", marginBottom: "1rem"}}>
                    <input style={{ marginRight: "0.1rem"}} value={carScreen} onChange={(e) => setCarScreen(e.target.checked ? "YES" : "NO")} checked={carScreen === "YES" ? true : false} type="checkbox" />&nbsp;
                    <span style={{ fontSize: "1.6rem", marginRight: "1.1rem"}}>{strings[lang].Vehicles32}</span>

                    <input style={{ marginRight: "0.1rem"}} value={carBluetooth} onChange={(e) => setCarBluetooth(e.target.checked ? "YES" : "NO")} checked={carBluetooth === "YES" ? true : false} type="checkbox" />&nbsp;
                    <span style={{ fontSize: "1.6rem", marginRight: "1.1rem"}}>{strings[lang].Vehicles33}</span>

                    <input style={{ marginRight: "0.1rem"}} value={carCamera} onChange={(e) => setCarCamera(e.target.checked ? "YES" : "NO")} checked={carCamera === "YES" ? true : false} type="checkbox" />&nbsp;
                    <span style={{ fontSize: "1.6rem", marginRight: "1.1rem"}}>{strings[lang].Vehicles34}</span>

                    <input style={{ marginRight: "0.1rem"}} value={carSensor} onChange={(e) => setCarSensor(e.target.checked ? "YES" : "NO")} checked={carSensor === "YES" ? true : false} type="checkbox" />&nbsp;
                    <span style={{ fontSize: "1.6rem", marginRight: "1.1rem"}}>{strings[lang].Vehicles35}</span>

                    <input style={{ marginRight: "0.1rem"}} value={carRoof} onChange={(e) => setCarRoof(e.target.checked ? "YES" : "NO")} checked={carRoof === "YES" ? true : false} type="checkbox" />&nbsp;
                    <span style={{ fontSize: "1.6rem", marginRight: "1.1rem"}}>{strings[lang].Vehicles36}</span>
                </div>
                <button type="submit" onClick={() => addCar()}>
                {strings[lang].Vehicles37}
                </button> 
              </div>
        </div>
      </div>

      {/* edit modal ------------------------------------ */}

      <div className={`booking-modal ${editmodal ? "active-modal" : ""}`} style={{ height: "55%" }}>
        {/* title */}
        {/* car info */}
        <div className="booking-modal__car-info">
              <div className="team-container__box" style={{ overflowY:"scroll" }}>
                <h2><i className="fa-solid fa-pen-to-square fa-2xl" onClick={() => closeEditModal()}></i></h2><br></br>
                <h3 style={{ textAlign: "left", marginTop: "1.5rem"}}>#{editId}</h3>
                <span>
                <input type="text" placeholder={strings[lang].Vehicles11} value={editName.toUpperCase()} onChange={(e) => setEditName(e.target.value.replace(/[^\w\s]/gi, ""))} />
                &nbsp; <input type="text" placeholder={strings[lang].Vehicles12} value={editModel.toUpperCase()} onChange={(e) => setEditModel(e.target.value.replace(/[^\w\s]/gi, ""))} />
                </span>
                <input type="number" placeholder={strings[lang].Vehicles13} value={editPlate} onChange={(e) => setEditPlate(e.target.value.replace(/[^\w\s]/gi, ""))} />
                <select value={editType} onChange={(e) => setEditType(e.target.value.replace(/[^\w\s]/gi, ""))}>
                    <option>{strings[lang].Vehicles14}</option>
                    <option value="Hatchback">Hatchback</option>
                    <option value="Sedan">Sedan</option>
                    <option value="SUV">SUV</option>
                    <option value="Bus">Bus</option>
                </select>
                <span>
                  <input type="number" placeholder={strings[lang].Vehicles15} value={editYear} onChange={(e) => setEditYear(e.target.value.replace(/[^\w\s]/gi, ""))} />
                &nbsp; <input type="number" placeholder={strings[lang].Vehicles16} value={editDaily} onChange={(e) => setEditDaily(e.target.value.replace(/[^\w\s]/gi, ""))} />
                </span>
                <span>
                  <input type="number" placeholder={strings[lang].Vehicles17} value={editWeekly} onChange={(e) => setEditWeekly(e.target.value.replace(/[^\w\s]/gi, ""))} />
                &nbsp; <input type="number" placeholder={strings[lang].Vehicles18} value={editMonthly} onChange={(e) => setEditMonthly(e.target.value.replace(/[^\w\s]/gi, ""))} />
                </span>
                <select value={editColor} onChange={(e) => setEditColor(e.target.value.replace(/[^\w\s]/gi, ""))}>
                    <option>{strings[lang].Vehicles19}</option>
                    <option value="WHITE">{strings[lang].Vehicles20}</option>
                    <option value="SILVER">{strings[lang].Vehicles21}</option>
                    <option value="GREY">{strings[lang].Vehicles22}</option>
                    <option value="BLACK">{strings[lang].Vehicles23}</option>
                    <option value="PURPLE">{strings[lang].Vehicles24}</option>
                    <option value="BLUE">{strings[lang].Vehicles25}</option>
                    <option value="GREEN">{strings[lang].Vehicles26}</option>
                    <option value="YELLOW">{strings[lang].Vehicles27}</option>
                    <option value="ORANGE">{strings[lang].Vehicles28}</option>
                    <option value="RED">{strings[lang].Vehicles29}</option>
                </select>
                <span>
                <input type="number" placeholder={strings[lang].Vehicles30} value={editDoors} onChange={(e) => setEditDoors(e.target.value.replace(/[^\w\s]/gi, ""))} />
                &nbsp; <input type="text" placeholder={strings[lang].Vehicles31} value={editGear} onChange={(e) => setEditGear(e.target.value.replace(/[^\w\s]/gi, ""))} />
                </span>
                <div className="box-form__Edit-type" style={{ marginTop: "1rem", marginBottom: "1rem"}}>
                    <input style={{ marginRight: "0.1rem"}} value={editScreen} onChange={(e) => setEditScreen(e.target.checked ? "YES" : "NO")} checked={editScreen === "YES" ? true : false} type="checkbox" />&nbsp;
                    <span style={{ fontSize: "1.6rem", marginRight: "1.1rem"}}>{strings[lang].Vehicles32}</span>

                    <input style={{ marginRight: "0.1rem"}} value={editBluetooth} onChange={(e) => setEditBluetooth(e.target.checked ? "YES" : "NO")} checked={editBluetooth === "YES" ? true : false} type="checkbox" />&nbsp;
                    <span style={{ fontSize: "1.6rem", marginRight: "1.1rem"}}>{strings[lang].Vehicles33}</span>

                    <input style={{ marginRight: "0.1rem"}} value={editCamera} onChange={(e) => setEditCamera(e.target.checked ? "YES" : "NO")} checked={editCamera === "YES" ? true : false} type="checkbox" />&nbsp;
                    <span style={{ fontSize: "1.6rem", marginRight: "1.1rem"}}>{strings[lang].Vehicles34}</span>

                    <input style={{ marginRight: "0.1rem"}} value={editSensor} onChange={(e) => setEditSensor(e.target.checked ? "YES" : "NO")} checked={editSensor === "YES" ? true : false} type="checkbox" />&nbsp;
                    <span style={{ fontSize: "1.6rem", marginRight: "1.1rem"}}>{strings[lang].Vehicles35}</span>

                    <input style={{ marginRight: "0.1rem"}} value={editRoof} onChange={(e) => setEditRoof(e.target.checked ? "YES" : "NO")} checked={editRoof === "YES" ? true : false} type="checkbox" />&nbsp;
                    <span style={{ fontSize: "1.6rem", marginRight: "1.1rem"}}>{strings[lang].Vehicles36}</span>
                </div>
                <button type="submit" onClick={() => editCar()}>
                {strings[lang].Vehicles38}
                </button> 
              </div>
        </div>
      </div>
          </>
  );
}

export default Vehicles;
