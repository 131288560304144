import { useState, useEffect } from "react";
import axios from 'axios';
import strings from './strings.json';

function Rent({ lang }) {
  const [modal, setModal] = useState(false);
  const [addmodal, setAddModal] = useState(false);
  const [cars, setCars] = useState("");
  const [carId, setCarId] = useState("");
  const [carName, setCarName] = useState("");
  const [carPlate, setCarPlate] = useState("");
  const [carOut, setCarOut] = useState("");
  const [carIn, setCarIn] = useState("");
  const [last, setLast] = useState(""); // Last index ID of Data

  const openModal = (cId, cName, cPlate, cOut, cIn) => {
    setCarId(cId);
    setCarName(cName);
    setCarPlate(cPlate);
    setCarOut(cOut);
    setCarIn(cIn);
    setModal(!modal);
    const modalDiv = document.querySelector(".booking-modal__car-info");
    modalDiv.scroll(0, 0);
  }

  const closeModal = () => {
    setModal(!modal);
  }

  const addModal = () => {
    setAddModal(!addmodal);
    const modalDiv = document.querySelector(".booking-modal__car-info");
    modalDiv.scroll(0, 0);
  }

  const [data, setData] = useState([]);

  const fetchData = async(e) => {
    try {
      const response = await axios({
        method: "get",
        url: "https://api.hardrockrental.net/getRents.php",
        headers: { "Content-Type": "multipart/form-data" },
      });
      setData(response.data);
      setLast(response.data[response.data.length-1].id+1);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  }

  const fetchCars = async() => {
    try {
      const response = await axios({
        method: "get",
        url: "https://api.hardrockrental.net/getCarNames.php",
        headers: { "Content-Type": "multipart/form-data" },
      });
      setCars(response.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  }

  useEffect(() => {
      fetchData();
      fetchCars();
  }, []);

  var bodyFormData = new FormData();
  bodyFormData.append('id', carId);
  bodyFormData.append('car', carName);
  bodyFormData.append('plate', carPlate);
  bodyFormData.append('car_out', carOut);
  bodyFormData.append('car_in', carIn);

  const updateRent = async() => {
    try {
      const response = await axios({
        method: "post",
        url: "https://api.hardrockrental.net/updateRent.php",
        data: bodyFormData,
        headers: { "Content-Type": "multipart/form-data" },
      });
      console.log(response.data);
      setModal(!modal);
      fetchData();
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  }

  var formData = new FormData();
  formData.append('id', last);

  const addRent = async() => {
    formData.append('car', carName);
    formData.append('plate', carPlate);
    formData.append('car_out', carOut);
    formData.append('car_in', carIn);
    try {
      await axios({
        method: "post",
        url: "https://api.hardrockrental.net/addRent.php",
        data: formData,
        headers: { "Content-Type": "multipart/form-data" },
      });
      setAddModal(!addmodal);
      fetchData();
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  }
  
  return (
    <>
      <div className="team-container" style={{ paddingBottom: "0px" }}>
        <p>
        {strings[lang].Vehicles1} {sessionStorage.getItem("userdata").toUpperCase()}{" "}
          <i className="fa-regular fa-face-grin-beam"></i>
          <br></br> {strings[lang].Vehicles2} <b>{data.length} {strings[lang].Rent1}</b>
        </p>
        <p></p>
        <button type="submit" style={{ borderRadius: "50px", boxShadow: "none"}} onClick={() => addModal()}>{strings[lang].Rent2}</button><br></br>
      </div>
      <div className="book-content__box">
      <table
                style={{
                  alignSelf: "center",
                  textAlign: lang === "ar" ? "right" : "left",
                  fontSize: "15px",
                }}
              >
                <thead>
                <tr>
                  <th>#</th>
                  <th>{strings[lang].Rent4}</th>
                  <th>{strings[lang].Rent5}</th>
                  <th>{strings[lang].Rent6}</th>
                  <th>{strings[lang].Rent7}</th>
                </tr></thead>
        {data &&
          data.map(function (d, idx) {
            return (
              <tbody key={idx}>
                <tr key={idx}>
                  <td>{d.id}.</td>
                  <td>{d.car}</td>
                  <td style={{ color: d.plate === 1 ? "red" : null }}>{d.plate === 1 ? "?" : d.plate}</td>
                  <td>{d.car_out}</td>
                  <td>{d.car_in}</td>
                  <td style={{ padding: "2rem"}}><i className="fa-solid fa-pen-to-square" style={{color: "red"}} onClick={() => openModal(d.id, d.car, d.plate, d.car_out, d.car_in)}></i></td>
                </tr>
              </tbody>
            );
          })}</table>
      </div>

      {/* modal ------------------------------------ */}

      <div className={`booking-modal ${modal ? "active-modal" : ""}`}>
        {/* title */}
        {/* car info */}
        <div className="booking-modal__car-info">
              <div className="team-container__box">
                <h2><i className="fa-solid fa-pen-to-square fa-2xl" onClick={() => closeModal()}></i></h2><br></br>
                <h3 style={{ textAlign: "left", marginTop: "1.5rem"}}>#{carId === "" ? "" : carId}</h3>
                <select value={carName} onChange={e => setCarName(e.target.value)}>
                {cars && cars.map(function(d, idx)
                { return (
                    <option value={d.name+" "+d.model} key={idx}>{d.name} {d.model}</option>
                )})}
                </select>
                <input type="number" placeholder={carPlate} value={carPlate} onChange={(e) => setCarPlate(e.target.value)} />
                <input type="date" placeholder={carOut} value={carOut} onChange={(e) => setCarOut(e.target.value)} />
                <input type="date" placeholder={carIn} value={carIn} onChange={(e) => setCarIn(e.target.value)} />
                <button type="submit" onClick={() => updateRent()}>
                {strings[lang].Vehicles38}
                </button> 
              </div>
        </div>
      </div>

      {/* modal ------------------------------------ */}

      <div className={`booking-modal ${addmodal ? "active-modal" : ""}`}>
        {/* title */}
        {/* car info */}
        <div className="booking-modal__car-info">
              <div className="team-container__box">
                <h2><i className="fa-regular fa-address-book fa-2xl" onClick={() => setAddModal(!addmodal)}></i></h2><br></br>
                <h3 style={{ textAlign: "left", marginTop: "1.5rem"}}>#{last}</h3>
                <select value={carName} onChange={e => setCarName(e.target.value)}>
                <option>{strings[lang].Rent8}</option>
                {cars && cars.map(function(d, idx)
                { return (
                    <option value={d.name+" "+d.model} key={idx}>{d.name} {d.model}</option>
                )})}
                </select>
                <input type="number" placeholder={strings[lang].Rent9} onChange={(e) => setCarPlate(e.target.value)} />
                <input type="date" placeholder={strings[lang].Rent6} onChange={(e) => setCarOut(e.target.value)} />
                <input type="date" placeholder={strings[lang].Rent7} onChange={(e) => setCarIn(e.target.value)} />
                <button type="submit" onClick={() => addRent()}>
                {strings[lang].Vehicles37}
                </button> 
              </div>
        </div>
      </div>
    </>
  );
}

export default Rent;
