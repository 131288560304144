import Hero from "../components/Hero";
import BookCar from "../components/BookCar";
import Banner from "../components/Banner";
import Faq from "../components/Faq";
import Footer from "../components/Footer";
import { useState, useEffect } from "react";

function Home() {

  const [lang, setLang] = useState('en');

  useEffect(() => {
    if (sessionStorage.getItem('lang') === 'ar') {
      setLang('ar');
    }
  }, []);

  return (
    <>
      <Hero lang={lang} />
      <BookCar lang={lang} setLang={setLang} />
      {/* <PlanTrip /> */}
      {/* <PickCar /> */}
      <Banner lang={lang} />
      {/* <ChooseUs /> */}
      {/* <Testimonials /> */}
      <Faq lang={lang} />
      {/* <Download /> */}
      <Footer lang={lang} />
    </>
  );
}

export default Home;
