import { useState, useEffect } from "react";
import { useNavigate, Link } from "react-router-dom";
import Footer from "../components/Footer";
import HeroPages from "../components/HeroPages";
import Logo from "../images/logo/logo.png";
import Vehicles from "../components/Vehicles";
import Services from "../components/Services";
import Form from "../components/Form";
import Rent from "../components/Rent";
import Upload from "../components/Upload";
import axios from 'axios';
import strings from '../components/strings.json';
import md5 from 'md5';

function Panel() {

  const navigate = useNavigate();
  const [nav, setNav] = useState(false);
  const ut = sessionStorage.getItem('usertype');
  const [component, setComponent] = useState(<Vehicles lang={sessionStorage.getItem('lang')} />);

  const openNav = () => {
    setNav(!nav);
  };

  const Signout = async() => {
    if (sessionStorage.getItem('userdata') !== null) {
    try {
      await axios({
        method: "post",
        url: "https://api.hardrockrental.net/getSignout.php",
        data: {id: parseInt(sessionStorage.getItem('userdata_id')), un: sessionStorage.getItem('userdata')},
        headers: { "Content-Type": "multipart/form-data" },
      });
        sessionStorage.removeItem('token');
        sessionStorage.removeItem('process');
        sessionStorage.removeItem('userdata_id');
        sessionStorage.removeItem('userstatus');
        sessionStorage.removeItem('userdata');
        sessionStorage.removeItem('usertype');
        navigate('/');
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  }
  }

  const Auth = () => {
    if (sessionStorage.getItem('token') !== md5(sessionStorage.getItem('process'))) {
      sessionStorage.removeItem('token');
      sessionStorage.removeItem('process');
      sessionStorage.removeItem('userdata_id');
      sessionStorage.removeItem('userstatus');
      sessionStorage.removeItem('userdata');
      navigate('/');
    }
  }

  useEffect(() => {
    setTimeout(() => {
      Auth();
    }, 100);
  }, )

  const openForm = () => {
    setComponent(<Form lang={sessionStorage.getItem('lang')} />);
    setNav(!nav);
  }

  const openServices = () => {
    setComponent(<Services lang={sessionStorage.getItem('lang')} />);
    setNav(!nav);
  }

  const openVehicles = () => {
    setComponent(<Vehicles lang={sessionStorage.getItem('lang')} />);
    setNav(!nav);
  }

  const openUpload = () => {
    setComponent(<Upload lang={sessionStorage.getItem('lang')} />);
    setNav(!nav);
  }

  const openRent = () => {
    setComponent(<Rent lang={sessionStorage.getItem('lang')} />);
    setNav(!nav);
  }

  return (
    <>
    <nav>
        {/* mobile */}
        <div className={`mobile-navbar ${nav ? "open-nav" : ""}`}>
          <div onClick={openNav} className="mobile-navbar__close">
            <i className="fa-solid fa-xmark"></i>
          </div>
          {ut === md5("admin") ?
          <ul className="mobile-navbar__links">
            <li>
              <Link onClick={openVehicles}>
              {strings[sessionStorage.getItem('lang')].Panel1}
              </Link>
            </li>
            <li>
              <Link onClick={openServices}>
              {strings[sessionStorage.getItem('lang')].Panel2}
              </Link>
            </li>
            <li>
              <Link onClick={openRent}>
              {strings[sessionStorage.getItem('lang')].Panel3}
              </Link>
            </li>
            <li>
              <Link onClick={openForm}>
              {strings[sessionStorage.getItem('lang')].Panel7}
              </Link>
            </li>
            <li>
              <Link onClick={openUpload}>
              {strings[sessionStorage.getItem('lang')].Panel4}
              </Link>
            </li>
            <li>
              <Link onClick={() => Signout()}>
              <i className="fa-solid fa-right-from-bracket"></i> &nbsp;{strings[sessionStorage.getItem('lang')].Panel5}
              </Link>
            </li>
          </ul>
          :
          <ul className="mobile-navbar__links">
            <li>
              <Link onClick={openVehicles}>
              {strings[sessionStorage.getItem('lang')].Panel1}
              </Link>
            </li>
            <li>
              <Link onClick={openRent}>
              {strings[sessionStorage.getItem('lang')].Panel3}
              </Link>
            </li>
            <li>
              <Link onClick={openForm}>
              {strings[sessionStorage.getItem('lang')].Panel7}
              </Link>
            </li>
            <li>
              <Link onClick={() => Signout()}>
              <i className="fa-solid fa-right-from-bracket"></i> &nbsp;{strings[sessionStorage.getItem('lang')].Panel5}
              </Link>
            </li>
          </ul>
          }
        </div>

        {/* desktop */}

        <div className="navbar">
          <div className="navbar__img">
            <Link to="/" onClick={() => window.scrollTo(0, 0)}>
              <img src={Logo} alt="logo-img" />
            </Link>
          </div>
          {ut === md5("admin") ?
          <ul className="navbar__links">
            <li>
              <Link className="home-link" onClick={() => setComponent(<Vehicles lang={sessionStorage.getItem('lang')} />)}>
                {strings[sessionStorage.getItem('lang')].Panel1}
              </Link>
            </li>
            <li>
              <Link className="about-link" onClick={() => setComponent(<Services lang={sessionStorage.getItem('lang')} />)}>
              {strings[sessionStorage.getItem('lang')].Panel2}
              </Link>
            </li>
            <li>
              <Link className="about-link" onClick={() => setComponent(<Rent lang={sessionStorage.getItem('lang')} />)}>
              {strings[sessionStorage.getItem('lang')].Panel3}
              </Link>
            </li>
            <li>
              <Link onClick={() => setComponent(<Form lang={sessionStorage.getItem('lang')} />)}>
              {strings[sessionStorage.getItem('lang')].Panel7}
              </Link>
            </li>
            <li>
              <Link className="models-link" onClick={() => setComponent(<Upload lang={sessionStorage.getItem('lang')} />)}>
              {strings[sessionStorage.getItem('lang')].Panel4}
              </Link>
            </li>
          </ul>
          :
          <ul className="navbar__links">
          <li>
            <Link className="home-link" onClick={() => setComponent(<Vehicles lang={sessionStorage.getItem('lang')} />)}>
              {strings[sessionStorage.getItem('lang')].Panel1}
            </Link>
          </li>
          <li>
            <Link className="about-link" onClick={() => setComponent(<Rent lang={sessionStorage.getItem('lang')} />)}>
            {strings[sessionStorage.getItem('lang')].Panel3}
            </Link>
          </li>
          <li>
              <Link onClick={() => setComponent(<Form lang={sessionStorage.getItem('lang')} />)}>
              {strings[sessionStorage.getItem('lang')].Panel7}
              </Link>
            </li>
        </ul>
          }
          <div className="navbar__buttons" onClick={() => Signout()}>
            <Link className="navbar__buttons__register">
            <i className="fa-solid fa-right-from-bracket"></i> &nbsp;{strings[sessionStorage.getItem('lang')].Panel5}
            </Link>
          </div>

          {/* mobile */}
          <div className="mobile-hamb" onClick={openNav}>
            <i className="fa-solid fa-bars"></i>
          </div>
        </div>
      </nav>
      <section className="contact-page">
        <HeroPages name={strings[sessionStorage.getItem('lang')].Panel6} />
        <div className="cotnainer">
          {component}
        </div>
        <Footer lang={sessionStorage.getItem('lang')} />
      </section>
    </>
  );
}

export default Panel;
