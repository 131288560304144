import Footer from "../components/Footer";
import HeroPages from "../components/HeroPages";
import { useRef, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import Navbar from "../components/Navbar";
import strings from '../components/strings.json';
import emailjs from "@emailjs/browser";
import axios from 'axios';

function Reservation() {
  const ref = useRef(null);
  const [totalCost, setTotalCost] = useState(0);
  const [rentCost, setRentCost] = useState(0);
  const [checked, setChecked] = useState([]);
  const [title, setTitle] = useState("");
  const [payment, setPayment] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [flight, setFlight] = useState("");
  const random = Math.random();
  const secDep = 51;
  var isChecked = (item) => checked.includes(item) ? "checked-item" : "not-checked-item";
  var updatedList = [];

  const formatDate = (date) => {
    const options = { day: '2-digit', month: 'long', year: 'numeric'};
    const formattedDate = new Date(date).toLocaleString('en-US', options);
    const [month, day, year] = formattedDate.split(' ');
    return `${month} ${day} ${year}`;
}

const date = new Date();
const year = date.getFullYear().toString().substr(-2);
const month = String(date.getMonth() + 1).padStart(2, '0');
const day = String(date.getDate()).padStart(2, '0');

  const location = useLocation();
  const car = location.state;
  const [dropOff, setDropOff] = useState(car.dropOff);
  const [pickUp, setPickUp] = useState(car.pickUp);
  var date1 = new Date(car.pickTime);
  var date2 = new Date(car.dropTime);
  var Difference_In_Time = date2.getTime() - date1.getTime();
  var Days = Difference_In_Time / (1000 * 3600 * 24);

  useEffect(() => {
    emailjs.init("fXWLmPTP1vBmAd_oQ");
    const totalWeeks = Math.floor(Days / 7);
      const remainingDays = Days % 7;
      const totalMonths = Math.floor(totalWeeks / 4);
      const remainingWeeks = totalWeeks % 4;

      const costDays = remainingDays * car.daily;
      const costWeeks = remainingWeeks * car.weekly;
      const costMonths = totalMonths * car.monthly;

      const totalCost = costDays + costWeeks + costMonths;
      setRentCost(totalCost);
      setTotalCost(totalCost);
    
      if (car.pickUp !== "Salmabad (Office)" || car.dropOff !== "Salmabad (Office)") {
        document.getElementById(1).checked = true;
        setTotalCost(totalCost+car.services[0].price);
        updatedList = [car.services[0].service+"_"+car.services[0].price];
        setChecked(updatedList);
      }
  }, [car, Days])

  const handleChange = (e, price) => {
    if (document.getElementById(1).checked === false) {
      setDropOff('Salmabad (Office)');
      setPickUp('Salmabad (Office)');
      document.getElementById(1).disabled = true;
    }
    updatedList = [...checked];
    if (e.checked) {
      updatedList = [...checked, e.value];
      setTotalCost(totalCost+price);
    } else {
      updatedList.splice(checked.indexOf(e.value), 1);
      setTotalCost(totalCost-price);
    }
    
    setChecked(updatedList);
  };

  const formCheck = (e) => {
    e.preventDefault();
    const errorMsg = document.querySelector(".error-message");
    let re = /^(([^<>()\]\\.,;:\s@"]+(\.[^<>()\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (
      title === "" ||
      payment === "" ||
      firstName.length < 2 ||
      lastName.length < 2 ||
      pickUp === "" ||
      dropOff === "" ||
      rentCost === "" ||
      totalCost === "" ||
      phone.length > 17 ||
      !re.test(email)
    ) {
      errorMsg.style.display = "flex";
    } else {
      configureSession();
      errorMsg.style.display = "none";
    }
  }

  const configureSession = async() => {
    const orderId = car.name.charAt(0)+car.model.charAt(0)+day+month+year+Math.floor(100 + Math.random() * 999);
    var bodyFormData = new FormData();
    bodyFormData.append('amount', (totalCost+secDep+((totalCost+secDep) * 0.1)).toFixed(2));
    bodyFormData.append('orderId', orderId);
    bodyFormData.append('returnUrl', 'https://hardrockrental.net/receipt?orderId='+orderId);
    bodyFormData.append('email', email);
    bodyFormData.append('firstName', firstName);
    bodyFormData.append('lastName', lastName);
    bodyFormData.append('mobilePhone', phone);
    bodyFormData.append('customer', title+". "+firstName+" "+lastName);
    bodyFormData.append('payment', payment);
    bodyFormData.append('servicesCost', (totalCost-rentCost).toFixed(2));
    bodyFormData.append('vat', ((totalCost+secDep) * 0.1).toFixed(2));
    bodyFormData.append('vehicle', car.name+" "+car.model);
    bodyFormData.append('pickup', pickUp);
    bodyFormData.append('dropoff', dropOff);
    bodyFormData.append('pickupTime', formatDate(car.pickTime));
    bodyFormData.append('dropoffTime', formatDate(car.dropTime));
    bodyFormData.append('services', checked.toString().replace(/[^-, A-Za-z]+/g,"") === "" ? "-" : checked.toString().replace(/[^-, A-Za-z]+/g,""));
    bodyFormData.append('subtotal', rentCost.toFixed(2));
    bodyFormData.append('flight', flight === "" ? "-" : flight);
    try {
      window.open("https://hardrockrental.net/receipt?orderId="+orderId, "_blank");
      // const response = await axios({
      //   method: "post",
      //   url: "https://hardrockrental.net/iniCheckout.php",
      //   data: bodyFormData,
      //   headers: { "Content-Type": "multipart/form-data" },
      // });
      // window.Checkout.configure({
      //     session: { 
      //     id: response.data.session.id
      //    }
      //   });
      // window.Checkout.showPaymentPage()
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  }

  const hideError = () => {
    const errorMsg = document.querySelector(".error-message");
    errorMsg.style.display = "none";
  };

  return (
    <>
    <Navbar />
      <section id="booking-section" className="book-section">
        <HeroPages name="Reservation" />
        <div className="container">

        <div className="book-content">
            <div className="book-content__box">
              <h2>{strings[location.state.lang].Reservation1}</h2>

              <p className="booking-done">
                Check your email to confirm an order.{" "}
                <i className="fa-solid fa-xmark"></i> 
              </p>

              <form className="box-form">
              <div className="team-container__box__img-div">
              <img src={"https://api.hardrockrental.net/imgs/"+(car.name+car.model).replace(" ","").toLowerCase()+'?n='+random} onError={e => { e.currentTarget.src = "https://api.hardrockrental.net/imgs/default.jpg" }} alt="team-img" />
                  <p style={{ fontSize: "16px", textAlign: "center"}}><b style={{ color: "black"}}>{car.name}</b> {car.model}</p>
                  
                </div>

                <div className="box-form__car-type">
                <label>
                <i className="fa-solid fa-location-dot"></i> {strings[location.state.lang].Reservation3}
                  </label>
                  <p style={{ fontSize: "15px"}}>{formatDate(car.pickTime)}</p>
                  <span><p style={{ fontSize: "15px"}}>{pickUp}</p></span>
                  <br></br>

                  <label><i className="fa-solid fa-location-dot"></i> {strings[location.state.lang].Reservation4}</label>
                  <p style={{ fontSize: "15px"}}>{formatDate(car.dropTime)}</p>
                  <span><p style={{ fontSize: "15px"}}>{dropOff}</p></span>
                </div>

                <div className="box-form__car-type">
                <label>
                <i className="fa-solid fa-hand-holding-dollar"></i> {strings[location.state.lang].Reservation5}
                </label>
                {car.services && (car.services).map(function(d, idx)
                { return (
                  <div key={idx}>
                    <input ref={ref} id={d.id} value={d.service+"_"+d.price} type="checkbox" onChange={e => handleChange(e.target, d.price)} />&nbsp;
                    <span className={isChecked(d.service)}>{d.service}</span>
                  </div>
                )
              })}
                <br></br>
                <p style={{ fontSize: "15px"}}>{strings[location.state.lang].Reservation6} (×{Days} {strings[location.state.lang].Reservation7}): {rentCost.toFixed(2)} {strings[location.state.lang].Reservation8}</p>
                <p style={{ fontSize: "15px"}}>{strings[location.state.lang].Reservation5}: {(totalCost-rentCost).toFixed(2)} {strings[location.state.lang].Reservation8}</p>
                <p style={{ fontSize: "15px"}}>{strings[location.state.lang].Reservation21}: {secDep} {strings[location.state.lang].Reservation8}</p>
                <p style={{ fontSize: "15px"}}>{strings[location.state.lang].Reservation20}: {((totalCost+secDep) * 0.1).toFixed(2)} {strings[location.state.lang].Reservation8}</p>
                <p style={{ fontSize: "15px"}}><i className="fa-solid fa-coins"></i> <span>{strings[location.state.lang].Reservation9}: {(totalCost+secDep+((totalCost+secDep) * 0.1)).toFixed(2)} {strings[location.state.lang].Reservation8}</span></p>
                </div>
              </form>
            </div>
          </div>

          <div className="book-content">
            <div className="book-content__box">
              <h2>{strings[location.state.lang].Reservation10}</h2>

              <p className="error-message">
              {strings[location.state.lang].Reservation2} <i className="fa-solid fa-xmark" onClick={hideError}></i>
              </p>

              <p className="booking-done">
                Check your email to confirm an order.{" "}
                <i className="fa-solid fa-xmark"></i>
              </p>

              <form className="box-form">
                <div className="box-form__car-type">
                <label><i className="fa-solid fa-user"></i> <span>
                {strings[location.state.lang].Reservation14}&nbsp;&nbsp;
                <input type="radio" id="r1" name="rr" value={strings[location.state.lang].Reservation11} onClick={e => setTitle(e.target.value)} /><label htmlFor="r1"><span></span>{strings[location.state.lang].Reservation11}{" "}</label>&nbsp;
                <input type="radio" id="r2" name="rr" value={strings[location.state.lang].Reservation12} onClick={e => setTitle(e.target.value)} /><label htmlFor="r2"><span></span>{strings[location.state.lang].Reservation12}{" "}</label>&nbsp;
                <input type="radio" id="r3" name="rr" value={strings[location.state.lang].Reservation13} onClick={e => setTitle(e.target.value)} /><label htmlFor="r3"><span></span>{strings[location.state.lang].Reservation13}{" "}</label>&nbsp; <b>*</b>
                </span></label>
                
                <input placeholder="First Name" value={firstName} onChange={e => setFirstName(e.target.value)}></input>
                </div>

                <div className="box-form__car-type">
                <label><i className="fa-solid fa-user"></i> <span>
                {strings[location.state.lang].Reservation15}&nbsp;&nbsp;<b>*</b>
                </span></label>
                
                <input placeholder="Last Name" value={lastName} onChange={e => setLastName(e.target.value)}></input>
                </div>

                <div className="box-form__car-type">
                <label>
                <i className="fa-solid fa-phone"></i> {strings[location.state.lang].Reservation16} <b>*</b>
                  </label>
                  <input placeholder="+973 12345678" value={phone} onChange={e => setPhone(e.target.value)}></input>
                </div>

                <div className="box-form__car-type">
                  <label>
                    <i className="fa-solid fa-envelope"></i> {strings[location.state.lang].Reservation17} <b>*</b>
                  </label>
                  <input placeholder="Example@mail.com" value={email} onChange={e => setEmail(e.target.value)}></input>
                </div>

                <div className="box-form__car-type">
                <label>
                <i className="fa-solid fa-plane"></i> {strings[location.state.lang].Reservation18}
                  </label>
                  <input placeholder="AB 1234" value={flight} onChange={e => setFlight(e.target.value)}></input>
                </div>

                <div className="box-form__car-type">
                <label><i className="fa-solid fa-wallet"></i> <span>
                {strings[location.state.lang].Reservation22}&nbsp;<b>*</b>
                <br></br><br></br>
                <input type="radio" id="p1" name="pp" value="Cash" onClick={e => setPayment(e.target.value)} /><label htmlFor="p1"><span></span>Cash{" "}</label>&nbsp;
                <input type="radio" id="p2" name="pp" value="BenefitPay" onClick={e => setPayment(e.target.value)} /><label htmlFor="p2"><span></span>Benefit Pay{" "}</label>&nbsp;
                </span></label></div>

                <div></div>
                <div></div>

                {payment === "BenefitPay" ? <div className="box-form__car-type">
                  <div className="iconBp" onClick={() => {navigator.clipboard.writeText("BH49AUBB00016318941001")}}></div>
                  <input className="input" readOnly value="BH49AUBB00016318941001"></input>
                </div> : null}
                {/* <button type="submit" onClick={() => {navigator.clipboard.writeText("BH49AUBB00016318941001")}}>
                {strings[location.state.lang].Reservation19}
                </button> */}
              </form>
            </div>
          </div>
        </div>
        <div className="book-banner">
          <div className="book-banner__overlay"></div>
          <div className="container">
            <div className="text-content">
              <h2>{strings[location.state.lang].Models1}</h2>
              <span>
                <i className="fa-brands fa-whatsapp"></i>
                <h3>+973 36333642</h3>
              </span>
            </div>
          </div>
        </div>
        <Footer lang={location.state.lang} />
      </section>
    </>
  );
}

export default Reservation;
