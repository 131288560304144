import Footer from "../components/Footer";
import HeroPages from "../components/HeroPages";
import { useState, useEffect } from "react";
import Logo from "../images/logo/logo.png";
import { Link } from "react-router-dom";
import emailjs from "@emailjs/browser";
import strings from '../components/strings.json';
import axios from 'axios';

function Receipt() {
  const [lang, setLang] = useState('en');
  const serviceId = "service_fbxnkku";
  const queryParameters = new URLSearchParams(window.location.search)
  const orderId = queryParameters.get("orderId")
  const Months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
  const [isLoading, setIsLoading] = useState(false);
  const [vehicle, setVehicle] = useState("");
  const [services, setServices] = useState("");
  const [servicesCost, setServicesCost] = useState(0);
  const [vat, setVat] = useState(0);
  const [subtotal, setSubtotal] = useState(0);
  const [status, setStatus] = useState("");
  const [currency, setCurrency] = useState("BHD");
  const [amount, setAmount] = useState(0);
  const [email, setEmail] = useState("");
  const [customer, setCustomer] = useState("");
  const [dropOff, setDropOff] = useState("");
  const [pickUp, setPickUp] = useState("");
  const [dropTime, setDropTime] = useState("");
  const [pickTime, setPickTime] = useState("");
  const [last, setLast] = useState("");
  const [cont, setCont] = useState({});
  const random = Math.random();

  useEffect(() => {
    if (lang === "en") {
      setCont(strings.en);
    } else if (lang === "ar") {
      setCont(strings.ar);
    }
    emailjs.init("fXWLmPTP1vBmAd_oQ");
    paymentStatus();
  }, [])

  const changeLang = () => {
    if (lang === "en") {
      setLang("ar");
      setCont(strings.ar);
      sessionStorage.setItem('lang', "ar");
    } else {
      setLang("en");
      setCont(strings.en);
      sessionStorage.setItem('lang', "en");
    }

    console.log(lang);
  }

  const paymentStatus = async() => {
    var bodyFormData = new FormData();
    // bodyFormData.append('url', 'https://afs.gateway.mastercard.com/api/rest/version/75/merchant/100255778/order/'+orderId);
    try {
      await axios({
        method: "post",
        url: "https://hardrockrental.net/getOrder.php",
        data: bodyFormData,
        headers: { "Content-Type": "multipart/form-data" },
      }).then((response) => {
        setStatus(response.data.status);
        setVehicle(response.data.custom.vehicle);
        setServices(response.data.custom.services);
        setServicesCost(response.data.custom.servicesCost);
        setVat(response.data.custom.vat);
        setSubtotal(response.data.custom.subtotal);
        setCurrency(response.data.currency);
        setAmount(response.data.amount);
        setEmail(response.data.customer.email);
        setCustomer(response.data.custom.customer);
        setDropOff(response.data.custom.dropoff);
        setPickUp(response.data.custom.pickup);
        setDropTime(response.data.custom.dropoffTime);
        setPickTime(response.data.custom.pickupTime);
        setIsLoading(true);
        if (response.data.status === "CAPTURED" && sessionStorage.getItem('HostedCheckout_sessionId') !== null) {
          emailCompany(response.data.custom.vehicle,
            response.data.custom.pickup,
            response.data.custom.pickupTime,
            response.data.custom.services,
            response.data.custom.servicesCost,
            response.data.custom.vat,
            response.data.custom.dropoff,
            response.data.custom.dropoffTime,
            response.data.custom.subtotal,
            response.data.currency,
            response.data.amount,
            response.data.custom.customer,
            response.data.customer.mobilePhone,
            response.data.customer.email,
            response.data.custom.flight
            );
          sessionStorage.removeItem('HostedCheckout_embedContainer');
          sessionStorage.removeItem('HostedCheckout_sessionId');
          sessionStorage.removeItem('HostedCheckout_merchantState');
          fetchData();
        }
      });
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  }

  const fetchData = async() => {
    try {
      const response = await axios({
        method: "get",
        url: "https://api.hardrockrental.net/getRents.php",
        headers: { "Content-Type": "multipart/form-data" },
      });
      setLast(response.data[response.data.length-1].id+1);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  }

  const emailCompany = async (vehicle, pickUp, pickTime, services, servicesCost, vat, dropOff, dropTime, subtotal, currency, amount, customer, phone, email, flight) => {
    const templateId = "template_clxa14g";
    try {
      await emailjs.send(serviceId, templateId, {
        orderId: orderId,
        vehicle: vehicle,
        pickupLocation: pickUp,
        pickupDate: pickTime,
        services: services,
        servicesCost: servicesCost+" "+currency,
        vat: vat+" "+currency,
        dropoffLocation: dropOff,
        dropoffDate: dropTime,
        subtotal: subtotal+" "+currency,
        total: amount.toFixed(2)+" "+currency,
        name: customer,
        phone: phone,
        email: email,
        flight: flight,
        to_email: "team@hardrockrental.net",
      });
      addRent(vehicle, pickTime, dropTime);
      // alert("Email sent successfully.");
    } catch (error) {
      console.log(error);
    } finally {
      // setLoading(false);
    }
  };

  const addRent = async(vehicle, pickTime, dropTime) => {
    var formData = new FormData();
    formData.append('id', last);
    formData.append('car', vehicle);
    formData.append('plate', 1);
    formData.append('car_out', pickTime.slice(pickTime.indexOf(',') + 1).replace(/\D/g, "")+"-"+(Months.indexOf(pickTime.replace(/[^a-z]/gi, ''))+1)+"-"+pickTime.substring(0, pickTime.indexOf(",")).replace(/\D/g, ""));
    formData.append('car_in', dropTime.slice(dropTime.indexOf(',') + 1).replace(/\D/g, "")+"-"+(Months.indexOf(dropTime.replace(/[^a-z]/gi, ''))+1)+"-"+dropTime.substring(0, dropTime.indexOf(",")).replace(/\D/g, ""));
    try {
      await axios({
        method: "post",
        url: "https://api.hardrockrental.net/addRent.php",
        data: formData,
        headers: { "Content-Type": "multipart/form-data" },
      });
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  }

  const emailCustomer = async () => {
    const templateId = "template_wcoc3s6";
    try {
      await emailjs.send(serviceId, templateId, {
        orderId: orderId,
        to_name: customer,
        pickupLocation: pickUp,
        pickupDate: pickTime,
        services: services,
        servicesCost: servicesCost+" "+currency,
        vat: vat+" "+currency,
        dropoffLocation: dropOff,
        dropoffDate: dropTime,
        subtotal: subtotal+" "+currency,
        total: amount.toFixed(2)+" "+currency,
        vehicle: vehicle,
        to_email: email,
      });
      // alert("Email sent successfully.");
    } catch (error) {
      console.log(error);
    } finally {
      // setLoading(false);
    }
  };

  return (
    <>
    <nav>
        {/* desktop */}

        <div className="navbar">
          <div className="navbar__img">
            <Link to="/" onClick={() => window.scrollTo(0, 0)}>
              <img src={Logo} alt="logo-img" />
            </Link>
          </div>
          <ul className="navbar__links">
            <li>
              <a className="home-link" id={lang} onClick={() => changeLang()}>
                {lang === "en" ? "العربية" : "English"}
              </a>
            </li>
          </ul>
        </div>
      </nav>
      <section className="about-page">
        <HeroPages name="Order Confirmation" />
        <div className="container">
          <div className="about-main">
            {isLoading ? <div className="about-main__text">
              <h2>{cont.Receipt3} {status === "CAPTURED" ? cont.Receipt4 : cont.Receipt5}</h2><br></br>
              <h3>{cont.Receipt2}: {orderId}</h3>
              <div className="about-main__text__icons">
                <div className="about-main__text__icons__box">
                  <span>
                  <img src={"https://api.hardrockrental.net/imgs/"+vehicle.replace(/\s/g, '').toLowerCase()+'?n='+random} onError={e => { e.currentTarget.src = "https://api.hardrockrental.net/imgs/default.jpg" }} alt="car-icon" />
                    <p>{vehicle} {cont.Receipt1}</p>
                  </span>
                  <span>
                    <p>{cont.Reservation5}: {services}</p>
                  </span>
                  <span>
                    <p>{cont.Reservation20}: </p>
                  </span>
                  <span>
                    <p><b>{cont.Reservation9}: </b></p>
                  </span>
                </div>
                <div className="about-main__text__icons__box">
                  <span>
                    <p>{subtotal} {currency}</p>
                  </span>
                  <span>
                    <p>{servicesCost} {currency}</p>
                  </span>
                  <span>
                    <p>{vat} {currency}</p>
                  </span>
                  <span>
                    <p><b>{amount.toFixed(2)} {currency}</b></p>
                  </span>
                </div>
              </div>
              <button type="submit" onClick={() => emailCustomer()}>{cont.Receipt6}</button>
            </div> : null}
          </div>
        </div>
      </section>
      <div className="book-banner">
        <div className="book-banner__overlay"></div>
        <div className="container">
          <div className="text-content">
            <h2>{cont.Receipt7}</h2>
            <span>
              <i className="fa-solid fa-phone"></i>
              <h3>+973 17005001</h3>
            </span>
          </div>
        </div>
      </div>
      <Footer lang={lang} />
    </>
  );
}

export default Receipt;
