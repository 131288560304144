import { useEffect, useState } from "react";
import { useNavigate, Link } from "react-router-dom";
import Logo from "../images/logo/logo.png";
import axios from 'axios';
import strings from './strings.json';
import md5 from 'md5';

function BookCar({ lang, setLang }) {

  const [cont, setCont] = useState({});
  const [carType, setCarType] = useState("");
  const [pickUp, setPickUp] = useState("");
  const [dropOff, setDropOff] = useState("");
  const [pickTime, setPickTime] = useState("");
  const [pick1Time, setPick1Time] = useState("");
  const [dropDisable, setDropDisable] = useState(true);
  const [dropTime, setDropTime] = useState("");
  const [drop1Time, setDrop1Time] = useState("");
  const [modalAnimation, setModalAnimation] = useState("booking-modal__car-info");
  const [un, setUn] = useState("");
  const [pw, setPw] = useState("");
  const [modal, setModal] = useState(false); //  class - active-modal
  const navigate = useNavigate();
  var currentDate = new Date();
  currentDate.setDate(currentDate.getDate() + 1);

  useEffect(() => {
    if (lang === "en") {
      setCont(strings.en);
    } else if (lang === "ar") {
      setCont(strings.ar);
    }
    if (modal && modal === true) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }
  }, [modal, lang]);
  
  var bodyFormData = new FormData();
  bodyFormData.append('Type', carType);
  // open modal when all inputs are fulfilled

  const openSignin = () => {
    if (sessionStorage.getItem('token') !== null && md5(sessionStorage.getItem('process')) !== null) {
      if (sessionStorage.getItem('token') === md5(sessionStorage.getItem('process'))) {
        navigate("/panel");
      }
    } else {
    setModal(!modal);
    const modalDiv = document.querySelector(".booking-modal__car-info");
    modalDiv.scroll(0, 0);}
  }

  const toSignin = async() => {
    if (sessionStorage.getItem('userdata') === null) {
    try {
      const response = await axios({
        method: "post",
        url: "https://api.hardrockrental.net/getSignin.php",
        data: {un: un, pw: md5(pw)},
        headers: { "Content-Type": "multipart/form-data" },
      });
      document.body.style.overflow = "auto";
      if (response.data[0].token !== null || response.data[0].token !== undefined) {
      sessionStorage.setItem('userdata', un);
      sessionStorage.setItem('token', response.data[0].token);
      sessionStorage.setItem('process', md5(un+'@'+md5(pw))+md5('$')+md5(md5(pw)+'@'+un));
      sessionStorage.setItem('userdata_id', response.data[0].user_id);
      sessionStorage.setItem('userstatus', parseInt(response.data[0].userstatus)+1);
      sessionStorage.setItem('usertype', md5(response.data[0].usertype))
      if (sessionStorage.getItem('lang') === null) {
        sessionStorage.setItem('lang', "en");
        navigate("/panel");
      } else {
        navigate("/panel");
      }
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      setModalAnimation("animated shake");

      setTimeout(() => {
        setModalAnimation("booking-modal__car-info");
      }, 1000);
    }
  }
  }

  const openModal = (e) => {
    e.preventDefault();
    const errorMsg = document.querySelector(".error-message");
    if (
      pickUp === "" ||
      dropOff === "" ||
      pickTime === "" ||
      dropTime === "" ||
      carType === ""
    ) {
      errorMsg.style.display = "flex";
    } else {
      fetchData();
      errorMsg.style.display = "none";
      // console.log(document.querySelector(".booking-modal__car-info"));
    }
  };

  let cars = [];

  const fetchData = async () => {
    try {
      const response = await axios({
        method: "post",
        url: "https://api.hardrockrental.net/getCars.php",
        data: bodyFormData,
        headers: { "Content-Type": "multipart/form-data" },
      });
      (response.data).map((item, index)=>{
        const dupl_index = cars.findIndex(itm_ =>itm_.name === item.name && itm_.model === item.model); // Merge duplicated cars to one
        if(dupl_index === -1){
        return cars.push(item)
        }else{
        return cars[dupl_index].Sum = Number(cars[dupl_index].Sum + item.Sum).toFixed(1)
        }});
      navigate("/models", { state: {carType: carType, pickUp: pickUp, dropOff: dropOff, pickTime: pickTime, dropTime: dropTime, carsData: cars, lang: lang}});
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const [showPassword, setShowPassword] = useState(false);

        const togglePasswordVisibility = () => {
          setShowPassword(!showPassword);
        };

  // taking value of booking inputs
  const handleCar = (e) => {
    setCarType(e.target.value);
  };

  const handlePick = (e) => {
    setPickUp(e.target.value);
  };

  const handleDrop = (e) => {
    setDropOff(e.target.value);
  };

  const handlePickTime = (e) => {
    setPickTime(e.target.value);
    let ptd= new Date(e.target.value);
    ptd.setDate(ptd.getDate() + 1);
    setPick1Time(ptd.toISOString().split("T")[0]);
    setDropDisable(false);
  };

  const handleDropTime = (e) => {
    setDropTime(e.target.value);
    let dtd = new Date(e.target.value);
    dtd.setDate(dtd.getDate() - 1);
    setDrop1Time(dtd.toISOString().split("T")[0]);
  };

  // hide message
  const hideMessage = () => {
    const doneMsg = document.querySelector(".booking-done");
    doneMsg.style.display = "none";
  };

  const changeLang = () => {
    if (lang === "en") {
      setLang("ar");
      setCont(strings.ar);
      sessionStorage.setItem('lang', "ar");
    } else {
      setLang("en");
      setCont(strings.en);
      sessionStorage.setItem('lang', "en");
    }
  }

  return (
    <>
    <nav>
        {/* desktop */}

        <div className="navbar">
          <div className="navbar__img">
            <Link to="/" onClick={() => window.scrollTo(0, 0)}>
              <img src={Logo} alt="logo-img" />
            </Link>
          </div>
          <ul className="navbar__links">
            <li>
              <Link className="home-link" id={lang} onClick={() => changeLang()}>
                {lang === "en" ? "العربية" : "English"}
              </Link>
            </li>
          </ul>
          <div className="navbar__buttons">
            <Link className="navbar__buttons__register" to={sessionStorage.getItem('userdata') !== null ? "/panel" : null} onClick={sessionStorage.getItem('userdata') !== null ? null : () => openSignin()}>
            {sessionStorage.getItem('userdata') !== null ? <i className="fa-solid fa-user-tie fa-sm" /> : <i className="fa-solid fa-right-to-bracket"></i>} &nbsp; {sessionStorage.getItem('userdata') !== null ? sessionStorage.getItem('userdata').toUpperCase() : cont.BookCar9}
            </Link>
          </div>

          {/* mobile */}
          <div className="mobile-hamb" onClick={openSignin}>
          {sessionStorage.getItem('userdata') !== null ? <i className="fa-solid fa-user-tie fa-sm" /> : <i className="fa-solid fa-right-to-bracket fa-sm"></i>}
          </div>
        </div>
      </nav>
      <section id="booking-section" className="book-section">
        {/* overlay */}
        <div
          onClick={openModal}
          className={`modal-overlay ${modal ? "active-modal" : ""}`}
        ></div>
        <div className="container">
          <div className="book-content">
            <div className="book-content__box">
              <h2>{cont.BookCar1}</h2>
              <p className="error-message">
              {cont.BookCar2} <i className="fa-solid fa-xmark"></i>
              </p>

              <p className="booking-done">
              {cont.BookCar2}
                <i onClick={hideMessage} className="fa-solid fa-xmark"></i>
              </p>

              <form className="box-form">
                <div className="box-form__car-type">
                  <label>
                    <i className="fa-solid fa-car"></i> &nbsp; {cont.BookCar3} <b>*</b>
                  </label>
                  <select value={carType} onChange={handleCar}>
                    <option>{cont.BookCar3}</option>
                    <option value="Hatchback">Hatchback</option>
                    <option value="Sedan">Sedan</option>
                    <option value="SUV">SUV</option>
                    <option value="Bus">Bus</option>
                  </select>
                </div>

                <div className="box-form__car-type">
                  <label>
                    <i className="fa-solid fa-location-dot"></i> &nbsp; {cont.BookCar4}{" "}
                    <b>*</b>
                  </label>
                  <select value={pickUp} onChange={handlePick}>
                    <option>{cont.BookCar5}</option>
                    <option value={"Salmabad (Office)"}>{cont.BookCar10}</option>
                    <option value={"Muharraq"}>{cont.BookCar11}</option>
                    <option value={"Manama"}>{cont.BookCar12}</option>
                    <option value={"Northern"}>{cont.BookCar13}</option>
                    <option value={"Central"}>{cont.BookCar14}</option>
                    <option value={"Southern"}>{cont.BookCar15}</option>
                  </select>
                </div>

                <div className="box-form__car-type">
                  <label>
                    <i className="fa-solid fa-location-dot"></i> &nbsp; {cont.BookCar6}{" "}
                    <b>*</b>
                  </label>
                  <select value={dropOff} onChange={handleDrop}>
                    <option>{cont.BookCar7}</option>
                    <option value={"Salmabad (Office)"}>{cont.BookCar10}</option>
                    <option value={"Muharraq"}>{cont.BookCar11}</option>
                    <option value={"Manama"}>{cont.BookCar12}</option>
                    <option value={"Northern"}>{cont.BookCar13}</option>
                    <option value={"Central"}>{cont.BookCar14}</option>
                    <option value={"Southern"}>{cont.BookCar15}</option>
                  </select>
                </div>

                <div className="box-form__car-type">
                  <label htmlFor="picktime">
                    <i className="fa-regular fa-calendar-days "></i> &nbsp;
                    {cont.BookCar4} <b>*</b>
                  </label>
                  <input
                    id="picktime"
                    value={pickTime}
                    onChange={handlePickTime}
                    min={new Date().toISOString().split('T')[0]}
                    max={drop1Time}
                    type="date"
                  ></input>
                </div>

                <div className="box-form__car-type">
                  <label htmlFor="droptime">
                    <i className="fa-regular fa-calendar-days "></i> &nbsp;
                    {cont.BookCar6} <b>*</b>
                  </label>
                  <input
                    id="droptime"
                    disabled={dropDisable}
                    value={dropTime}
                    onChange={handleDropTime}
                    min={pick1Time}
                    type="date"
                    style={{ borderColor: dropDisable === true && "silver" , color: dropDisable === true && "silver" }}
                  ></input>
                </div>

                <button onClick={openModal} type="submit">
                {cont.BookCar8}
                </button>
              </form>
            </div>
          </div>
        </div>
      </section>

      {/* modal ------------------------------------ */}

      <div className={`booking-modal ${modal ? "active-modal" : ""}`}>
        {/* title */}
        {/* car info */}
        <div className={modalAnimation}>
              <div className="team-container__box">
                <h2><i className="fa-solid fa-user-tie fa-2xl" onClick={openSignin}></i></h2><br></br>
                <input placeholder="Username" style={{ borderColor: modalAnimation === "booking-modal__car-info" ? "" : "#ea0b2b" }} value={un} onChange={e => setUn(e.target.value)}></input>
                <div className="input-container">
              <input
                type={showPassword ? "text" : "password"}
                value={pw}
                placeholder="Password"
                onChange={(e) => setPw(e.target.value)}
                className="password-input"
                style={{ borderColor: modalAnimation === "booking-modal__car-info" ? "" : "#ea0b2b" }}
              />
                {showPassword ? <i 
                 className="fa-regular fa-eye" onClick={togglePasswordVisibility}></i> : <i className="fa-regular fa-eye-slash" onClick={togglePasswordVisibility}></i>}
            </div>

                <button type="submit" onClick={() => toSignin()}>
                {cont.BookCar9}
                </button> 
              </div>
        </div>
      </div>
    </>
  );
}

export default BookCar;
